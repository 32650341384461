.container {
  height: 120vh;
  max-height: 150vh;
  background-color: #f4f6fb;
}

.userProfile {
  box-shadow: 0px 0px 10px rgba(38, 51, 77, 0.08);
  border-radius: 4px;
}
