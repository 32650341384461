.socket-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 10px 50px 0px rgba(38, 51, 77, 0.05);
}

.socket-name {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
}

.socket-id {
  font-weight: 400;
  font-size: 12.8356px;
  color: #69768b;
}

.selected {
  background-color: #011f5d;
}

.selectedFontColor {
  color: #fff;
}
