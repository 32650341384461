.spaceContainerWrapper {
  position: relative;
}

.spaceContainer {
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: #ffffff;
  border: 1.5px solid #f5f6f7;
  box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
  border-radius: 8px;
  z-index: 5;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  display: none;
}

.dropdownMenu.show {
  display: block;
}

.dropdownMenu li {
  cursor: pointer;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #69768b;

  &:hover {
    background-color: rgba(221, 221, 221, 0.137);
  }
}

.overflow {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 30vh;
}

.overflow::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}
