.scheduleUsageContainer {
  border: 1px solid #eaecf3;
  border-radius: 12px;
}

.scheduleUsage {
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #eaecf3;
}
