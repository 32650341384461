.selectedDate {
  background-color: #011f5d;
  color: #fff;
  border-radius: 50px;
}

.nonSelectedDate {
  background-color: #f9f9f9;
  color: #6f757e;
  border-radius: 50px;
}
