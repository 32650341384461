.tank {
  width: 115px;
  height: 130px;
  border-radius: 16px;
  background: var(--WidgetElementColor, #011f5d0f);
  overflow: hidden;
  position: relative;

  .wave {
    animation: animate 1s linear infinite;
    transition: height 2s linear;
    background-image: url("../../assets/images/wave.svg");
    background-position-x: 0;
    content: "";
    height: 50%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@keyframes animate {
  to {
    background-position-x: 200px;
  }
}

@keyframes animate2 {
  from {
    height: 0;
  }
  50% {
    height: 75%;
  }
  to {
    height: 0;
  }
}
