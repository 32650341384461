.container-white {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 60px 120px rgba(38, 51, 77, 0.05);
  padding: 15px 30px;
}

.container-transparent {
  padding: 30px;
}

.container-dash {
  border: 1px dashed #69768b;
  border-radius: 8px;
  padding: 30px;
}

.card-container {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 36px 120px 0px rgba(38, 51, 77, 0.1);
}

.content {
  position: relative;
}
