.label {
  border-radius: 8px;
  color: #383941;
  border-radius: 5px;
  border: 2px solid var(--Btn-Primary, #011f5d);
  background: rgba(42, 39, 216, 0.05);
}

.time {
  font-size: 8px;
  padding-left: 4px;
}

.overflowDeviceList {
  overflow-y: scroll;
  max-height: 45vh;
}

.overflowDeviceList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.overflowDeviceList::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}
