.icon-button {
  background-color: transparent;
  color: #69768b;
  transition-property: background-color;
  transition-duration: 200ms;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  line-height: 24px;
  span {
    display: flex;
    width: 20px;
    height: 20px;
    background-color: #011f5d;
    color: #ffffff;
    border-radius: 5px;
  }
}

.transparentWithBorder {
  border-radius: 12px;
  padding: 9px;
  background: #fff;
  color: #69768b;
  font-weight: 500;
  border: 1px solid #eaecf3;
}
