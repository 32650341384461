.deviceCard {
  border-radius: 8px 8px 0 0;
  background: #ffffff;
  box-shadow: 0px 36px 50px 0px #26334d0a;
  position: relative;

  .deviceName {
    width: 100px;
  }
}

.coverBorderRadius {
  border-radius: 8px;
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 5;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 36px 50px 0px #26334d0a;
}

.dropdownMenu.show {
  display: block;
}

.overflow {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 39vh;
}

.overflow::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

.overflow::-webkit-scrollbar-thumb {
  background-color: #eaecf3;
  border-radius: 20px;
}

.hr {
  border: 1px solid transparent;
  border-top: 1px solid #e2e6f1;
  padding: 20px 0 0 0;
}
