.button {
  background-color: #f9f9f9;
  color: #69768b;
  border-radius: 50px;
}

.selected {
  background-color: #011f5d;
  color: #fff;
  border-radius: 50px;
}
